import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    height: 40,
    border: '1px solid',
    borderColor: theme.palette.action.disabled,
    borderRadius: 32,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.main
    }
  },
  inputAdornment: {
    marginLeft: 12
  },
  icon: {
    color: theme.palette.action.active
  }
}));

const SearchInput = ({ value, onChange }) => {
  const classes = useStyles();
  const [like, setLike] = React.useState(value);

  const handleChange = debounce(e => {
    setLike(e.target.value.toUpperCase());
  }, 400);

  React.useEffect(() => {
    if (onChange) {
      onChange(like);
    }
  }, [like, onChange]);

  return (
    <InputBase
      classes={{ root: classes.input }}
      placeholder="Pesquisar..."
      startAdornment={
        <InputAdornment position="start" classes={{ root: classes.inputAdornment }}>
          <Icon path={mdiMagnify} size={1} className={classes.icon} />
        </InputAdornment>
      }
      onChange={handleChange}
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default React.memo(SearchInput);
