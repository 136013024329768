import { createTheme } from '@material-ui/core/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR, QUARTENARY_COLOR } from './colors';

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  },
  shadows: [
    ...defaultTheme.shadows,
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%), 0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(0, 0, 0, 0.14)'
  ],
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'unset'
      }
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: 'white'
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    }
  }
});

export const secondaryTheme = createTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: TERTIARY_COLOR
    },
    secondary: {
      main: QUARTENARY_COLOR
    }
  }
});

export default theme;
