import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Icon, AppBar, Hidden, Toolbar, IconButton } from '@material-ui/core';
import Logo from '../../Logo';
import Account from './Account';
import Collects from './Collects';

export const StyledAppBar = styled(AppBar)`
  @media print {
    display: none !important;
  }
`;

const TopBar = ({ handleToggleMobile, handleToggleDesktop, ...rest }) => (
  <StyledAppBar {...rest}>
    <Toolbar>
      <Hidden lgUp>
        <IconButton edge="start" color="inherit" aria-label="voltar" onClick={handleToggleMobile}>
          <Icon>menu</Icon>
        </IconButton>
      </Hidden>
      <Hidden mdDown>
        <IconButton edge="start" color="inherit" aria-label="voltar" onClick={handleToggleDesktop}>
          <Icon>menu</Icon>
        </IconButton>
      </Hidden>
      <Box ml={3}>
        <Logo height="30px" />
      </Box>
      <Box ml={2} flexGrow={1} />
      <Box ml={2}>
        <Collects />
      </Box>
      <Box ml={2}>
        <Account />
      </Box>
    </Toolbar>
  </StyledAppBar>
);

TopBar.propTypes = {
  handleToggleMobile: PropTypes.func.isRequired,
  handleToggleDesktop: PropTypes.func.isRequired
};

export default TopBar;
