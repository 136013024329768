import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { secondaryTheme } from 'setup/theme';

const SecondaryTheme = ({ children }) => (
  <MuiThemeProvider theme={secondaryTheme}>{children}</MuiThemeProvider>
);

SecondaryTheme.propTypes = {
  children: PropTypes.node.isRequired
};

export default SecondaryTheme;
