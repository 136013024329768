import React, { useState } from 'react';
import {
  Box,
  Grid,
  List,
  Divider,
  Popover,
  ListItem,
  Button,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { mdiMapMarkerMultiple, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchInput from 'components/SearchInput';
import useAuth from 'hooks/useAuth';
import useShippingCarriers from 'hooks/useShippingCarriers';

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

export const StyledButton = styled(Button)`
  justify-content: flex-start;
  width: 100%;
  background-color: #5345;
`;

export const StyledListItem = styled(ListItem)`
  flex-direction: row;
  text-align: left;
`;

export const StyledBox = styled(Box)`
  display: flex;
`;

const useStyles = makeStyles({
  listItemButton: {
    paddingLeft: 24
  },
  listItemSecondaryAction: {
    right: 24
  }
});

const Origins = () => {
  const classes = useStyles();
  const { shipping_carrier: shippingCarrier, setShippingCarrier } = useAuth();
  const { shipping_carriers, loading } = useShippingCarriers();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !!anchorEl;
  const [filtering, setFiltering] = useState('');
  const filteredOrigins = shipping_carriers?.filter(item =>
    item.name.toUpperCase().includes(filtering)
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFiltering('');
  };

  const handleUpperCaseBeforeSearch = value => setFiltering(value.toUpperCase());

  const handleChangeShippingCarrier = shipping_carrier => {
    setShippingCarrier(shipping_carrier);
    handleClose();
  };

  return (
    <>
      <List>
        <StyledListItem
          button
          onClick={handleClick}
          onClose={handleClose}
          className={classes.listItemButton}
        >
          <ListItemIcon>
            <Icon path={mdiMapMarkerMultiple} size={1} />
          </ListItemIcon>
          <ListItemText primary={shippingCarrier?.name} secondary="Origem" />
          <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
            <ArrowDropDownIcon />
          </ListItemSecondaryAction>
        </StyledListItem>
      </List>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <>
          <Grid item xs={12}>
            <Box m={3}>
              <SearchInput value={filtering} onChange={handleUpperCaseBeforeSearch} />
            </Box>
          </Grid>
          <Divider />
          {loading && <LinearProgress />}
          <List>
            {filteredOrigins?.length > 0 ? (
              filteredOrigins.map(item => (
                <StyledListItem
                  key={item?.id}
                  button
                  onClick={() => handleChangeShippingCarrier(item)}
                >
                  <ListItemText primary={item?.name} />
                </StyledListItem>
              ))
            ) : (
              <StyledListItem alignItems="center">
                <Grid container direction="column" alignItems="center">
                  <Typography variant="body2">Nenhum resultado encontrado</Typography>
                </Grid>
              </StyledListItem>
            )}
          </List>
          <Divider />
          <List>
            <ListItem button disabled>
              <ListItemIcon>
                <Icon path={mdiPlus} size={1} />
              </ListItemIcon>
              <ListItemText primary="Cadastrar origem" />
            </ListItem>
          </List>
        </>
      </Popover>
    </>
  );
};

export default Origins;
