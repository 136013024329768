export const AUTH_ERROR_MESSAGE = 'Esse usuário não é contato de uma transportadora.';
export const LOCALSTORAGE_TOKEN = '@token';
export const LOCALSTORAGE_AUTH = '@auth';
export const LOCALSTORAGE_EXTERNAL_USER = '@external-user';
export const HOME_PATH = '/';
export const STATUS_CODE_500 = 500;
export const STATUS_CODE_404 = 404;
export const STATUS_CODE_INVALID = 300;

export default {
  AUTH_ERROR_MESSAGE,
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_EXTERNAL_USER,
  HOME_PATH,
  STATUS_CODE_500,
  STATUS_CODE_404,
  STATUS_CODE_INVALID
};
