import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'hooks/useAuth';

const AuthGuard = ({ children, path }) => {
  const { isAuthenticated, shipping_carrier } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!shipping_carrier && path !== '/selecionar-transportadora') {
    return <Redirect to="/selecionar-transportadora" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default AuthGuard;
