import { gql } from '@apollo/client';

const FETCH_SHIPPING_CARRIERS = gql`
  query fetchShippingCarriers($matrix_id: Int!) {
    fetchShippingCarriers(matrix_id: $matrix_id) {
      id
      name
    }
  }
`;

export default FETCH_SHIPPING_CARRIERS;
