import { useState } from 'react';

const useMessage = () => {
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState(undefined);

  const onClose = () => setMessage(undefined);

  const handleSuccess = text => {
    setMessage(text);
    setVariant('success');
  };

  const handleError = text => {
    setMessage(text);
    setVariant('error');
  };

  const handleInfo = text => {
    setMessage(text);
    setVariant('info');
  };

  const handleWarning = text => {
    setMessage(text);
    setVariant('warning');
  };

  return {
    message,
    variant,
    setMessage,
    onClose,
    handleSuccess,
    handleError,
    handleInfo,
    handleWarning
  };
};

export default useMessage;
