export const PRIMARY_COLOR = '#453E97';
export const SECONDARY_COLOR = '#2DC1D0';
export const TERTIARY_COLOR = '#D6008F';
export const QUARTENARY_COLOR = '#FFD448';

export default {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  QUARTENARY_COLOR
};
