import { useEffect, useState } from 'react';
import checkForUpdates, { needToCheckForUpdates } from 'api/update';

const TWO_MINUTES = 120000;

const useCheckForUpdates = initialState => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let interval;

    if (needToCheckForUpdates && !state.isShowingToast) {
      interval = setInterval(() => checkForUpdates({ state, setState }), TWO_MINUTES);
    }

    return () => {
      clearInterval(interval);
    };
  }, [state]);

  return { state, setState };
};

export default useCheckForUpdates;
