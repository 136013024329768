import { gql } from '@apollo/client';

const FETCH_MATRICES = gql`
  query fetchMatrices {
    fetchMatrices {
      id
      name
      logo_path
    }
  }
`;

export default FETCH_MATRICES;
