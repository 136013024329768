import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import StyledProgressWrapper from './styled';

const Progress = ({ type, ...rest }) => (
  <>
    {type === 'LinearProgress' ? (
      <LinearProgress {...rest} style={{ width: '100%' }} />
    ) : (
      <StyledProgressWrapper>
        <CircularProgress {...rest} />
      </StyledProgressWrapper>
    )}
  </>
);

Progress.propTypes = {
  type: PropTypes.oneOfType(['LinearProgress', 'CircularProgress'])
};

Progress.defaultProps = {
  type: 'CircularProgress'
};

export default Progress;
