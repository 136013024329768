/* eslint-disable no-use-before-define */
import React from 'react';
import { find } from 'lodash';
import { Box, List, makeStyles, ListSubheader, Badge } from '@material-ui/core';
import SecondaryTheme from 'components/SecondaryTheme';
import Origins from 'components/Origins';
import MatrixSelector from 'components/MatrixSelector';
import sections from 'components/Sidebar/sections';
import { useLocation, matchPath } from 'react-router-dom';
import NavItem from 'components/Sidebar/NavItem';
import useAuth from 'hooks/useAuth';

const useStyles = makeStyles(() => ({
  listItem: {
    paddingLeft: '24px',
    paddingBottom: '0px;',
    paddingTop: '16px;'
  },
  badge: {
    top: 23,
    right: -27
  }
}));

function reduceChildRoutes({ acc, pathname, item, depth, data, handleCloseMobile }) {
  const key = item.id + depth;

  const open = matchPath(pathname, {
    path: item.href,
    exact: false
  });

  if (item.items) {
    acc.push(
      <NavItem
        key={key}
        icon={item.icon}
        info={item.info}
        open={Boolean(open)}
        depth={depth}
        title={item.title}
        data={data}
        handleCloseMobile={handleCloseMobile}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          data,
          handleCloseMobile
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        key={key}
        href={item.href}
        handleCloseMobile={handleCloseMobile}
        icon={item.icon}
        info={item.info}
        open={Boolean(open)}
        depth={depth}
        title={item.title}
        data={data}
      />
    );
  }

  return acc;
}

function renderNavItems({ data, items, pathname, depth = 0, handleCloseMobile }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            data,
            handleCloseMobile
          }),
        []
      )}
    </List>
  );
}

const NavMenu = ({ handleCloseMobile }) => {
  const location = useLocation();
  const { member, shipping_carrier, collects, overdue_collects, tickets_count } = useAuth();
  const collects_count = React.useMemo(() => {
    const id = parseInt(shipping_carrier.id);
    return find(collects, collect => collect.shipping_carrier_id === id)?.total;
  }, [shipping_carrier, collects]);
  const overdue_collects_count = React.useMemo(() => {
    const id = parseInt(shipping_carrier.id);
    return find(overdue_collects, overdue_collect => overdue_collect.shipping_carrier_id === id)
      ?.total;
  }, [shipping_carrier, overdue_collects]);

  const hasPermission = permissions => (permissions ? permissions.includes(member.role) : true);

  const makeSectionsFiltered = items =>
    items
      .filter(section => hasPermission(section.permission))
      .map(section => ({
        ...section,
        items: section.items ? makeSectionsFiltered(section.items) : undefined
      }));

  const filteredSections = makeSectionsFiltered(sections);

  const classes = useStyles();
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box pl={2} pr={2} pt={0}>
        <MatrixSelector />
      </Box>
      <Box pt={0} pb={8}>
        <Origins />
        {filteredSections.map(section => (
          <List
            key={section.id}
            subheader={
              <ListSubheader
                key={section.id}
                className={classes.listItem}
                disableSticky
                disableGutters
              >
                {section.new ? (
                  <SecondaryTheme>
                    <Badge color="secondary" badgeContent="novo" classes={{ badge: classes.badge }}>
                      {section.subheader}
                    </Badge>
                  </SecondaryTheme>
                ) : (
                  section.subheader
                )}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: section.items,
              pathname: location.pathname,
              data: { tickets_count, collects_count, overdue_collects_count },
              handleCloseMobile
            })}
          </List>
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(NavMenu);
