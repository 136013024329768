import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Rollbar from 'rollbar';
import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { AuthProvider } from 'contexts/AuthContext';
import { ExternalUserProvider } from 'contexts/ExternalUserContext';
import useMessage from 'hooks/useMessage';
import useCheckForUpdates from 'hooks/useCheckForUpdates';
import theme from 'setup/theme';
import client from 'setup/apolloClient';
import Context, { initialState } from 'setup/context';
import Toast from 'components/Toast';
import NotistackProvider from './components/NotistackProvider';
import routes, { Routes } from './routes';

const isDevelop = process.env.NODE_ENV === 'development';
const rollbarOptions = {
  accessToken: process.env.REACT_APP_ROLLBAR_CODE,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV
  }
};
const rollbar = !isDevelop && new Rollbar(rollbarOptions);

const App = () => {
  const { Provider } = Context;
  const message = useMessage();
  const { state, setState } = useCheckForUpdates(initialState);
  const { isShowingToast, toastProps } = state;

  return (
    <Provider
      value={{
        ...state,
        message,
        setState,
        rollbar
      }}
    >
      <CssBaseline />
      <ExternalUserProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <AuthProvider>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                  <NotistackProvider>
                    <>
                      {isShowingToast && <Toast {...toastProps} />}
                      <Routes routes={routes} />
                    </>
                  </NotistackProvider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </AuthProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ExternalUserProvider>
    </Provider>
  );
};

export default App;
