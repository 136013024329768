import { STATUS_CODE_500, STATUS_CODE_404 } from './constants';
import { getInitialToken, removeTokens } from '../../contexts/AuthContext';

function errorHandle({ graphQLErrors = [], networkError }) {
  this.handleNetworkErrors(networkError);
  this.handleGraphQLErrors(graphQLErrors);
  graphQLErrors.map(this.printGraphQLError);
  this.printNetworkError(networkError);
}

function handleNetworkErrors({ statusCode } = {}) {
  if (statusCode === STATUS_CODE_500 || statusCode === STATUS_CODE_404 || statusCode === 401) {
    this.removeTokenAndRedirectHome();
  }
}

function handleGraphQLErrors(graphQLErrors = []) {
  const hasAuthErrors = graphQLErrors.find(error => this.isAuthError(error));
  if (hasAuthErrors) {
    this.removeTokenAndRedirectHome();
  }
}

function printGraphQLError({ message, locations, path }) {
  console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
}

function printNetworkError(networkError) {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
}

function isAuthError({ code }) {
  return [100, 140, 141, 142, 144].includes(code);
}

function removeTokenAndRedirectHome() {
  const { isSessionStorage } = getInitialToken();
  removeTokens(isSessionStorage);
  window.location.href = '/login';
}

export default {
  errorHandle,
  handleNetworkErrors,
  handleGraphQLErrors,
  printGraphQLError,
  printNetworkError,
  isAuthError,
  removeTokenAndRedirectHome
};
