import { gql } from '@apollo/client';

const AUTH_MATRIX = gql`
  mutation authMatrix($matrix_id: Int!) {
    authMatrix(matrix_id: $matrix_id) {
      token
    }
  }
`;

export default AUTH_MATRIX;
