import React from 'react';
import PropTypes from 'prop-types';
import Wrapper, { StyledActions, StyledMessage } from './styled';

const Toast = ({ message, actions }) => (
  <Wrapper>
    <StyledMessage>{message}</StyledMessage>
    {actions && <StyledActions>{actions}</StyledActions>}
  </Wrapper>
);

Toast.propTypes = {
  message: PropTypes.oneOfType([PropTypes.any]).isRequired,
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

Toast.defaultProps = {
  actions: null
};

export default Toast;
