import React from 'react';
import PropTypes from 'prop-types';

const GuestGuard = ({ children }) => <>{children}</>;

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired
};

export default GuestGuard;
