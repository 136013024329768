import { ApolloClient, /*createHttpLink,*/ InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import errorHandleCallback from './errorHandle';
import authLinkCallback from './authLink';

const { REACT_APP_GRAPHQL_URL } = process.env;

const uploadLink = createUploadLink({
  uri: REACT_APP_GRAPHQL_URL
});

const authLink = setContext(authLinkCallback.authLink.bind(authLinkCallback));
const errorLink = onError(errorHandleCallback.errorHandle.bind(errorHandleCallback));

const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, uploadLink]),
  cache: new InMemoryCache()
});

export default apolloClient;
