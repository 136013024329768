import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logo = ({ color, height }) => {
  const { PUBLIC_URL } = process.env;
  const logo = `${PUBLIC_URL}/images/logo-${color}.svg`;

  return (
    <Link to="/">
      <img height={height} src={logo} alt="Central do Frete" />
    </Link>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string
};

Logo.defaultProps = {
  color: 'light',
  height: '72'
};

export default Logo;
