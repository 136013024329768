import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';

const LOCALSTORAGE_MENU = '@menuIsClosed';

const SET_MOBILE = 'SET_MOBILE';
const TOGGLE_MOBILE = 'TOGGLE_MOBILE';
const TOGGLE_DESKTOP = 'TOGGLE_DESKTOP';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MOBILE: {
      return {
        ...state,
        isOpenMobile: action.paylaod
      };
    }
    case TOGGLE_MOBILE: {
      return {
        ...state,
        isOpenMobile: !state.isOpenMobile
      };
    }
    case TOGGLE_DESKTOP: {
      localStorage.setItem(LOCALSTORAGE_MENU, JSON.stringify(state.isOpenDesktop));
      return {
        ...state,
        isOpenDesktop: !state.isOpenDesktop
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const MainWrapper = styled.main`
  @media print {
    padding-top: 0;
  }
  display: flex;
  padding-top: 84px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
`;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 310
    }
  }
}));

const Sidebar = ({ children }) => {
  const classes = useStyles();

  const initialIsOpenDesktop = !JSON.parse(localStorage.getItem(LOCALSTORAGE_MENU));
  const [state, dispatch] = React.useReducer(reducer, {
    isOpenMobile: false,
    isOpenDesktop: initialIsOpenDesktop
  });
  const handleCloseMobile = () => dispatch({ type: SET_MOBILE, payload: false });
  const handleToggleMobile = () => dispatch({ type: TOGGLE_MOBILE });
  const handleToggleDesktop = () => dispatch({ type: TOGGLE_DESKTOP });

  return (
    <Container>
      <TopBar handleToggleMobile={handleToggleMobile} handleToggleDesktop={handleToggleDesktop} />
      <NavBar state={state} handleCloseMobile={handleCloseMobile} />
      <MainWrapper
        className={clsx(classes.content, {
          [classes.contentShift]: state.isOpenDesktop
        })}
      >
        {children}
      </MainWrapper>
    </Container>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired
};

export default Sidebar;
