import styled from 'styled-components';

export const StyledActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const StyledMessage = styled.div`
  color: rgba(0, 0, 0, 0.6);
`;

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem;
  background-color: #ffff00;
`;
