import { getInitialToken } from '../../contexts/AuthContext';

function authLink(_, { headers }) {
  const { token } = getInitialToken();
  if (!token) {
    return this.createNewHeaders(headers);
  }
  return this.createHeadersWithAuthorization(headers, token);
}

function createHeadersWithAuthorization(headers, token) {
  const authorization = this.addTokenToAuthorization(token);
  const headersWithAuthorization = this.addAuthorizationToHeaders(headers, authorization);
  return this.createNewHeaders(headersWithAuthorization);
}

function createNewHeaders(headers) {
  return {
    headers
  };
}

function addAuthorizationToHeaders(headers, authorization) {
  return {
    ...headers,
    authorization
  };
}

function addTokenToAuthorization(token) {
  return `Bearer ${token}`;
}

export default {
  authLink,
  createHeadersWithAuthorization,
  createNewHeaders,
  addAuthorizationToHeaders,
  addTokenToAuthorization
};
