import { gql } from '@apollo/client';

const CHECK_MATRIX_STATUS = gql`
  query checkMatrixStatus($shipping_carrier_id: Int!) {
    checkMatrixStatus(shipping_carrier_id: $shipping_carrier_id) {
      matrix {
        id
        logo_path
        name
      }
      shipping_carrier {
        id
        name
      }
      member {
        id
        status
        role
      }
      user {
        name
        email
        avatar
      }
      collects {
        shipping_carrier_id
        total
      }
      overdue_collects {
        shipping_carrier_id
        total
      }
      tickets_count
    }
  }
`;

export default CHECK_MATRIX_STATUS;
