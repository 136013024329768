import React from 'react';

export const initialState = {
  isShowingToast: false,
  toastProps: {
    message: ''
  }
};

const Context = React.createContext();

export default Context;
