import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { LOCALSTORAGE_EXTERNAL_USER } from 'setup/apolloClient/constants';

export const ExternalUserContext = React.createContext({});
const getExternalUser = () => JSON.parse(window.localStorage.getItem(LOCALSTORAGE_EXTERNAL_USER));

export const ExternalUserProvider = ({ children }) => {
  const initialUser = getExternalUser();
  const [user, setUser] = useState(initialUser);
  const [showPassword, setShowPassword] = useState(!!initialUser);

  const setExternalUser = exUser => {
    setUser(exUser);
    window.localStorage.setItem(LOCALSTORAGE_EXTERNAL_USER, JSON.stringify(exUser));
  };

  return (
    <ExternalUserContext.Provider
      value={{
        externalUser: user,
        setExternalUser,
        showPassword,
        setShowPassword
      }}
    >
      {children}
    </ExternalUserContext.Provider>
  );
};

ExternalUserProvider.propTypes = {
  children: PropTypes.node.isRequired
};
