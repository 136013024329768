import { useQuery } from '@apollo/client';
import useAuth from 'hooks/useAuth';
import FETCH_SHIPPING_CARRIERS from './fetchShippingCarriers';

const useShippingCarriers = () => {
  const { matrix } = useAuth();

  const { data, loading, error } = useQuery(FETCH_SHIPPING_CARRIERS, {
    variables: {
      matrix_id: matrix.id
    }
  });

  return {
    shipping_carriers: data?.fetchShippingCarriers ?? [],
    loading,
    error
  };
};

export default useShippingCarriers;
